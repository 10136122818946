.wrapper {
    overflow-x: auto;
    display: inline-flex;
    flex-direction: column;
}

.columnsHolder {
    display: flex;
    align-items: center;
    gap: 20px;
}

.operationalColumn {
    width: 224px;
}

.greyRow {
    background: var(--grey);
    margin-top: 4px;
}

.dataHolder {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: black;
    text-align: center;
    width: 100%;
    border: solid 1px var(--tableGrey);
    border-radius: 12px;
    padding: 10px 5px;
    box-sizing: border-box;
}

.dataHolderHeader {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: black;
}