.select {
    border: none;
    box-shadow: none;
    outline: none;
    height: 46px;
    line-height: 46px;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: black;

    box-sizing: border-box;
    margin-bottom: 12px;

    >span {
        height: 0px;
        display: none;
    }

    :global(.inline-react-select__indicator-separator),
    :global(.react-select__indicator-separator) {
        background: none;
    }

    :global(.inline-react-select__indicatorContainer),
    :global(.react-select__indicatorContainer) {
        color: var(--brown);
        background-size: 11px 6px;
        background-repeat: no-repeat;
    }

    :global(.react-select__single-value) {
        padding: 0px;
        margin: 0px;
        font-weight: 400;
        font-size: 14px;
        color: black;
    }

    :global(.react-select__control) {
        position: relative;
        z-index: 2;
        background: var(--grey);
        cursor: pointer;
        width: 100%;
        max-width: 100%;
        padding: 0px 20px;
        display: inline-flex;
        border-radius: 12px;
        border: none;
        box-shadow: none;
        margin-top: 0;
        min-height: 0px;

        &:hover,
        &:global(.react-select__control--menu-is-open),
        &:global(.react-select__control--is-focused) {
            background: var(--darkBlue);

            :global(.react-select__single-value) {
                color: white;
            }
        }
    }

    :global(.react-select__value-container) {
        padding: 0px;
    }

    :global(.react-select__input-container) {
        padding: 0px;
        margin: 0px;
        font-weight: 400;
        font-size: 14px;
        color: black;

        input {
            width: 100%;
            font-weight: 400;
            font-size: 14px;
            color: black;
        }
    }

    :global(.react-select__menu) {
        top: 100%;
        width: 100%;
        padding: 0;
        background-color: var(--grey);
        border: none;
        border-top: 0;
        border-radius: 4px;
        overflow: auto;
        box-shadow: none;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        padding-top: 10px;
        margin-top: -10px;
        margin-bottom: 0px;
        z-index: 1;
    }

    :global(.react-select__option) {
        height: auto;
        line-height: 15px;
        font-weight: 500;
        font-size: 14px;
        color: black;
        background-color: transparent;
        border: none;
        padding: 15px;
        cursor: pointer;

        &:first-of-type {
            border: none;
        }

        &:last-of-type {
            border: none;
        }
    }

    :global(.react-select__option--is-selected) {
        background: rgba(66, 187, 255, 0.09);
    }






    :global(.inline-react-select__single-value) {
        padding: 0px;
        margin: 0px;
        font-weight: 400;
        font-size: 14px;
        color: var(--midBlue);
    }

    :global(.inline-react-select__control) {
        position: relative;
        z-index: 2;
        background: transparent;
        cursor: pointer;
        width: 100%;
        max-width: 100%;
        padding: 0px;
        display: inline-flex;
        border-radius: 12px;
        border: none;
        box-shadow: none;
        margin-top: 0;
        min-height: 0px;

        &:hover,
        &:global(.react-select__control--menu-is-open),
        &:global(.react-select__control--is-focused) {
            background: transparent;
        }
    }

    :global(.inline-react-select__value-container) {
        padding: 0px;
    }

    :global(.inline-react-select__input-container) {
        padding: 0px;
        margin: 0px;
        font-weight: 400;
        font-size: 14px;
        color: black;

        input {
            width: 100%;
            font-weight: 400;
            font-size: 14px;
            color: black;
        }
    }

    :global(.inline-react-select__menu) {
        top: 100%;
        width: 100%;
        padding: 0;
        background-color: var(--grey);
        border: none;
        border-top: 0;
        border-radius: 4px;
        overflow: auto;
        box-shadow: none;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        padding-top: 10px;
        margin-top: -10px;
        margin-bottom: 0px;
        z-index: 1;
    }

    :global(.inline-react-select__option) {
        height: auto;
        line-height: 15px;
        font-weight: 500;
        font-size: 14px;
        color: black;
        background-color: transparent;
        border: none;
        padding: 15px;
        cursor: pointer;

        &:first-of-type {
            border: none;
        }

        &:last-of-type {
            border: none;
        }
    }

    :global(.inline-react-select__option--is-selected) {
        background: rgba(66, 187, 255, 0.09);
    }
}