.footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    position: relative;

    .submitted {
        height: 50px;
        line-height: 50px;
        color: green;
    }

    .submitButton {
        border-radius: 36px;
        text-decoration: none;
        text-align: center;
        height: 50px;
        line-height: 50px;
        padding: 0px 50px;
        color: var(--darkBlue);
        background: var(--lightBlue);
        cursor: pointer;

        &.marginTop {
            margin-top: 25px;
        }

        &.inactive {
            background: rgba(66, 187, 255, 0.28);
            cursor: inherit;
        }
    }

    .formError {
        color: var(--red);
        position: absolute;
        bottom: 60px;
        right: 0px;
        font-size: 12px;
        font-weight: 500;
    }
}