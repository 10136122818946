
.intro {
    background: var(--darkBlue);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-grow: 1;
}

.loginBlock {
    padding: 30px 0px;

    .logo {
        height: 36px;
        margin: 0 auto;
        display: block;
        margin-bottom: 35px;
    }

    .introText {
        color: var(--lightBlue);
        font-size: 32px;
        line-height: 38px;
        text-align: center;
        font-weight: 600;
        margin-bottom: 35px;
    }

    .formBlock {
        width: 311px;
        height: 230px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-self: center;
        align-items: center;

        .switchButton {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 23px;
            text-decoration: underline;
            margin-bottom: 23px;
            margin-top: 15px;
            color: var(--lightBlue);
            cursor: pointer;
        }

        .whatsappText {
            font-weight: 600;
            font-size: 16px;
            line-height: 17px;
            color: var(--lightBlue);
            text-align: center;
            margin-bottom: 28px;
        }

        .inputsHolder {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .errorText {
                font-size: 14px;
                line-height: 16px;
                color: var(--red);
                position: absolute;
                top: calc(100% - 14px);
                left: 0;
                right: 0;
                text-align: center;
            }
        }

        .loginInput {
            width: 100%;
            color: var(--lightBlue);
            border-radius: 12px;
            padding: 13px 18px;
            font-size: 16px;
            background: transparent;
            border: solid 1px var(--lightBlue);
            outline: none;
            box-shadow: none;
            margin-bottom: 16px;

            &::-webkit-input-placeholder { /* WebKit browsers */
                color: var(--lightBlue);
            }
            &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                color: var(--lightBlue);
            }
            &::-moz-placeholder { /* Mozilla Firefox 19+ */
                color: var(--lightBlue);
            }
            &:-ms-input-placeholder { /* Internet Explorer 10+ */
                color: var(--lightBlue);
            }

            &.error {
                border-color: var(--red);
            }
        }

        .codeInput {
            width: 159px;
            color: var(--lightBlue);
            border-radius: 12px;
            padding: 13px 18px;
            font-size: 16px;
            background: transparent;
            border: solid 1px var(--lightBlue);
            outline: none;
            box-shadow: none;
            margin-bottom: 16px;
            text-align: center;

            &::-webkit-input-placeholder { /* WebKit browsers */
                color: var(--lightBlue);
            }
            &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                color: var(--lightBlue);
            }
            &::-moz-placeholder { /* Mozilla Firefox 19+ */
                color: var(--lightBlue);
            }
            &:-ms-input-placeholder { /* Internet Explorer 10+ */
                color: var(--lightBlue);
            }

            &.error {
                border-color: var(--red);
            }
        }

        .loginButton {
            border-radius: 36px;
            text-decoration: none;
            text-align: center;
            height: 50px;
            line-height: 50px;
            padding: 0px 50px;
            color: var(--darkBlue);
            background: var(--lightBlue);
            cursor: pointer;

            &.marginTop {
                margin-top: 25px;
            }

            &.inactive {
                background: rgba(66, 187, 255, 0.28);
                cursor: inherit;
            }
        }
    }
}