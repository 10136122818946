.chartLegend {
    display: flex;
    flex-direction: column;
    margin-top: 48px;
    padding-left: 16px;
}
.chartLegendLi {
    display:flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: black;
    margin-bottom: 4px;
    cursor: pointer;

    span {
        border-radius: 4px;
    }

    .colorBox {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
}

.tooltip {
    position: absolute;
    border-radius: 50%;
    width: 60px;
    margin-left: -30px;
    display: block;
    background: white;
    border-radius: 5px;
    box-shadow: 0 0 9px 1px #00000026;

    .tooltipSum {
        padding: 4px 2px;
        text-align: center;
        font-weight: 700;
        font-size: 12px;
    }

    .tooltipData {
        border-left: solid 4px;
        border-right: solid 4px;
        padding: 4px 2px;
        text-align: center;
        font-weight: 700;
        position: relative;

        .tooltipHover {
            display: none;
            position: absolute;
            left: -40px;
            right: -40px;
            bottom: 10px;
            background: white;
            border: solid 4px;
            border-radius: 5px;
            font-size: 10px;
            padding: 4px;
            text-align: left;
            font-weight: 400;
            box-shadow: 0 0 9px 1px #00000026;
            z-index: 10;
        }

        &:hover {
            .tooltipHover {
                display: block;
            }
        }
    }
}