.groupRow {
    position: relative;
    padding: 14px 25px;
    background: white;
    border-radius: 12px;
    max-width: 1200px;
    margin-bottom: 10px;

    .editButton {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
    
        &:hover,
        &:active {
            opacity: 0.7;
        }
    
        img {
            width: 32px;
        }
    }

    .groupHeading {
        font-weight: 700;
        font-size: 20px;
        line-height: 16px;
        color: black;
        padding: 5px 0px;
        display: inline-block;

        border-bottom: solid 3px var(--midBlue);

        margin-bottom: 23px;
    }

    &:last-of-type {
        margin-bottom: 0px;
    }
}

.productRow {
    display: flex;
    margin: 15px 0px;
    align-items: center;

    .productTitle {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: black;
        padding: 1px 4px;
        margin-right: 4px;
        width: 220px;
        border-radius: 6px;

        &.error {
            background: var(--lightRed);
            color: white;
        }
    }

    .productAmount {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: black;
        padding: 1px 4px;
        margin-right: 30px;
        width: 90px;
        border-radius: 6px;

        &.error {
            background: var(--lightRed);
            color: white;
        }
    }

    .plusOrder,
    .toMake {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: black;
        padding: 2px 4px;
        margin-left: 13px;
        width: 80px;
        text-align: center;
        background: var(--grey);
        border-radius: 6px;
        z-index: 5;

        &.noBackground {
            background: transparent;
        }

        &.error {
            background: var(--lightRed);
            color: white;
        }
    }

    &.noMarginBottom {
        margin-bottom: -15px;

        .plusOrder,
        .toMake {
            display: flex;
            text-align: left;
            padding-left: 0px;

            span {
                margin-left: 3px;
                display: flex;
                align-items: center;

                a {
                    cursor: pointer;

                    &.active,
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}

.statusLineHolder {
    flex: 1;
    margin-right: 70px;
}