.loaderHolder {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;

    .loader {
        display: inline-block;
        width: 80px;
        height: 80px;

        &:after {
            content: " ";
            display: block;
            width: 64px;
            height: 64px;
            margin: 8px;
            border-radius: 50%;
            border: 6px solid var(--darkBlue);
            border-color: var(--darkBlue) transparent var(--darkBlue) transparent;
            animation: loader 1.2s linear infinite;
        }
    }
    
    @keyframes loader {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}