.chartLegend {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding-left: 16px;
}
.chartLegendLi {
    display:flex;
    align-items: center;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: black;
    margin-bottom: 2px;
    cursor: pointer;

    span {
        border-radius: 50%;
    }

    .colorBox {
        width: 10px;
        height: 10px;
        margin-right: 10px;
    }
}