.tableWrapper {

}

.salesTable {
    border: none;
    width: 100%;

    tr {
        th {
            color: black;
            font-weight: 500;
            font-size: 15px;
            line-height: 16px;
            height: 1px;

            .th {
                border-radius: 4px;
                background: var(--darkBlue);
                color: white;
                padding: 10px;
                text-align: left;
                margin: 2px;
                height: calc(100% - 20px);

                span {
                    display: block;

                    a {
                        cursor: pointer;
    
                        &.active,
                        &:hover {
                            opacity: 0.7;
                        }

                        img {
                            height: 10px;
                        }
                    }
                }
            }
        }

        td {
            color: black;
            font-weight: 500;
            font-size: 15px;
            line-height: 16px;
            height: 1px;

            .td {
                border-radius: 4px;
                background: white;
                padding: 10px;
                text-align: left;
                margin: 2px;
                height: calc(100% - 20px);
            }
        }
    }
}

.productsRow {
    font-size: 12px;
    margin-bottom: 10px;

    span {
        font-weight: 700;
        font-size: 14px;
    }

    &:last-of-type {
        margin-bottom: 0px;
    }
}

.loadMoreButton {
    border-radius: 36px;
    text-decoration: none;
    text-align: center;
    height: 50px;
    line-height: 50px;
    padding: 0px 50px;
    color: var(--darkBlue);
    background: var(--lightBlue);
    cursor: pointer;
    display: block;
    width: 200px;
    box-sizing: border-box;
    margin: 50px auto;
}