.modalContainer {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;

    .modalBackdrop {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        background: #0000007d;
    }

    .modalContent {
        border-radius: 12px;
        background: white;
        padding: 30px 40px;
        position: relative;
        z-index: 2;
        width: 860px;
        max-width: calc(100vw - 40px);
        max-height: calc(100vh - 40px);
        box-sizing: border-box;
        overflow-y: auto;

        &.thin {
            width: 466px;
            max-width: calc(100vw - 40px);
        }

        &.medium {
            width: 590px;
            max-width: calc(100vw - 40px);
        }

        .modalHeader {
            font-weight: 600;
            font-size: 32px;
            line-height: 38px;
            color: black;
            margin-bottom: 36px;
        }

        .modalClose {
            position: absolute;
            top: 18px;
            right: 14px;
            cursor: pointer;
        }

        .modalBody {
            height: 100%;
        }
    }
}