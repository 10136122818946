.avatarChange {
    cursor: pointer;
    position: relative;
    display: inline-block;

    .avatarPlaceholder {
        height: 77px;
        width: 77px;
        background: var(--grey);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-decoration: none;
    }

    .plusButton {
        position: absolute;
        right: 0px;
        bottom: 0px;
        z-index: 2;
    }
}

.imageChange {
    cursor: pointer;
    position: relative;
    display: block;
    margin-bottom: 20px;

    .imagePlaceholder {
        height: 40px;
        width: 100%;
        background: var(--grey);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-decoration: none;
    }

    .plusButton {
        position: absolute;
        right: 0px;
        bottom: 0px;
        z-index: 2;
    }
}

.header {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;

    color: black;

    margin-bottom: 15px;

    img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
}

.flatLink {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-decoration-line: underline;
    color: var(--lightBlue);
    margin: 13px 0px;
    cursor: pointer;
    display: inline-block;
}

.footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 56px;
    position: relative;

    .submitButton {
        border-radius: 36px;
        text-decoration: none;
        text-align: center;
        height: 50px;
        line-height: 50px;
        padding: 0px 50px;
        color: var(--darkBlue);
        background: var(--lightBlue);
        cursor: pointer;

        &.marginTop {
            margin-top: 25px;
        }

        &.inactive {
            background: rgba(66, 187, 255, 0.28);
            cursor: inherit;
        }
    }

    .formError {
        color: var(--red);
        position: absolute;
        bottom: 60px;
        right: 0px;
        font-size: 12px;
        font-weight: 500;
    }
}

.list {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: black;
    margin-bottom: 7px;

    strong {
        font-weight: 700;
        margin-left: 5px;
    }
}

.flexRow {
    display: flex;
    justify-content: space-between;
}

.flexInlineRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    margin-bottom: 5px;

    .inlineUnderlinedRow {
        display: flex;
        align-items: center;
        padding: 7px 0px;
        border-bottom: solid 1px var(--tableGrey);
        flex: 1;
        margin-right: 15px;
    
        &:last-of-type {
            border-bottom: none;
        }
    }

    &:last-of-type .inlineUnderlinedRow {
        border-bottom: none;
    }
}



.inlineTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: black;
}

.inlineInput {
    width: 240px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    box-sizing: border-box;

    span {
        margin-left: 5px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: black;
        display: block;
        width: 85px;
        text-align: right;
        flex-shrink: 0;
        box-sizing: border-box;
    }
}

.spaceBetween {
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.flexLabelsHolder {
    display: flex;
    align-items: flex-end;
}

.inlineLabel {
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: black;
    text-align: center;
    display: block;
    width: 100%;
}

.headerInput {
    background: transparent;
    box-shadow: none;
    outline: none;
    border-radius: 12px;
    height: 44px;
    line-height: 44px;
    width: 70px;
    padding: 0px 5px;
    font-weight: 400;
    font-size: 14px;
    border: solid 1px var(--tableGrey);
    color: black;
    box-sizing: border-box;
    margin-bottom: 12px;
    text-align: center;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}

.removeButton {
    color: var(--red);
    font-size: 14px;
    font-weight: 700;
    display: block;
    text-align: right;
    margin-top: 50px;
    cursor: pointer;
}