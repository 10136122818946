.speedometer {
    background: var(--darkBlue);
    border-radius: 12px;
    min-height: 180px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    
    .numberHolder {
        line-height: 19px;
        height: 24px;
        background: var(--lightBlue);
        color: var(--darkBlue);
        font-weight: bold;
        font-size: 21px;
        min-width: 50px;
        text-align: center;
        position: absolute;
        left: 0;
        top: 10px;
        padding: 0px 5px;
    }

    .title {
        color: white;
        text-align: right;
        position: absolute;
        top: 10px;
        right: 19px;
        font-weight: 800;
        font-size: 12px;
        line-height: 12px;
        width: 100px;
    }

    .sum {
        color: white;
        text-align: right;
        position: absolute;
        left: 10px;
        bottom: 7px;
        font-weight: 800;
        font-size: 12px;
        line-height: 12px;
    }

    .text {
        color: white;
        text-align: right;
        position: absolute;
        right: 10px;
        bottom: 7px;
        font-weight: 800;
        font-size: 12px;
        line-height: 12px;
    }

    .speedometerBody {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: relative;
        margin-bottom: 35px;

        img {
            width: 136px;
            margin-left: 4px;
            margin-bottom: -12px;
        }

        span {
            color: var(--lightBlue);
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            text-align: center;
        }

        .speedTick {
            position: absolute;
            bottom: 36px;
            left: calc(50% - 4px);
            transform: rotate(-125deg);
            transform-origin: center bottom;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 2px 44px 2px;
            border-color: transparent transparent var(--lightRed) transparent;
        }
    }
}