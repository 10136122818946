.dropDown {
    position: relative;

    .dropDownToggle {
        cursor: pointer;
    }

    .dropDownBody {
        display: none;
        position: absolute;
        z-index: 2;
        right: 0px;
        top: calc(100% + 10px);
        padding: 20px 10px;
        background: white;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
        border-radius: 24px 0px 24px 24px;

        &.visible {
            display: block;
        }
    }
}