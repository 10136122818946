.groupColumn {
    height: 500px;
    width: 350px;
    border-radius: 12px;
    background: var(--grey);
    overflow: hidden;
    flex-shrink: 0;
    position: relative;

    .editButton {
        position: absolute;
        right: 15px;
        bottom: 15px;
        cursor: pointer;
    
        &:hover,
        &:active {
            opacity: 0.7;
        }
    
        img {
            width: 32px;
        }
    }

    .groupHeading {
        padding: 17px 20px;
        font-weight: 700;
        font-size: 17px;
        line-height: 16px;
        color: #000;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .groupPricing {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: white;

            input {
                display: inline-block;
                padding: 10px 0px;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                color: black;
                margin-right: 3px;
                background: white;
                border-radius: 12px;
                width: 70px;
                text-align: center;
                box-shadow: none;
                outline: none;
                border: none;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }
            }
        }
    }

    .productsList {
        padding: 0px 11px;
        overflow-y: auto;
        max-height: calc(100% - 70px);
        box-sizing: border-box;
        padding-bottom: 30px;

        .productRow {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: solid 1px var(--tableGrey);
            padding: 10px;

            .productTitle {
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: black;
            }

            .productPrice {
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                color: black;
                margin-left: 10px;
                width: 100px;
                text-align: right;
            }

            &:last-of-type {
                border-bottom: none;
            }
        }
    }
}