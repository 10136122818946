.filterBar {
    display: flex;
    overflow-x: auto;
    gap: 12px;
    margin-bottom: 15px;

    .filterBtn {
        height: 30px;
        border-radius: 36px;
        border: solid 1px black;
        padding: 1px;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        padding: 0px 15px;
        font-weight: 600;

        span {
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            color: black;
        }

        &.selected {
            border-color: var(--lightBlue);
            background: var(--lightBlue);
        }

        .coloredCircle {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: block;
            margin-right: 6px;
            margin-left: -14px;
        }
    }
}