.wrapper {
    overflow-x: auto;
    display: inline-flex;
    flex-direction: column;
}

.columnsHolder {
    display: flex;
    gap: 20px;
}

.operationalColumn {
    width: 224px;
}

.notInSystem {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0px;
    border-radius: 12px;
    height: 100%;
    background: white;
}

.saleCard {
    padding: 10px;
    border-radius: 12px;
    background: white;
    margin-top: 6px;

    .firstRow {
        display: flex;
        font-weight: 700;
        font-size: 11px;
        line-height: 16px;
        color: black;
        margin-bottom: 10px;

        span {
            font-weight: 700;
            font-size: 11px;
            line-height: 0px;
            color: white;
            display: flex;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            margin-right: 5px;
            background: #42BBFF;
            flex-shrink: 0;
        }

        div {
            display: flex;
            flex-direction: column;
        }
    }

    .secondRow {
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        margin-bottom: 5px;
    }

    .thirdRow {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: black;

        div {
            display: flex;
            align-items: center;

            div {
                margin: 0px 2px;
            }
        }
    }
}