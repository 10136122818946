.input {
    background: var(--grey);
    box-shadow: none;
    outline: none;
    border-radius: 12px;
    height: 44px;
    line-height: 44px;
    width: 100%;
    padding: 0px 20px;
    font-weight: 400;
    font-size: 14px;
    border: solid 1px var(--grey);

    color: black;

    box-sizing: border-box;
    margin-bottom: 12px;

    &.textarea {
        height: auto;
        line-height: 18px;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    &:not(:placeholder-shown) {
        border-color: var(--tableGrey);
    }

    &:focus {
        border-color: var(--lightBlue);
    }    

    &::-webkit-input-placeholder { /* WebKit browsers */
        color: black;
        font-weight: 400;
        font-size: 14px;
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: black;
        font-weight: 400;
        font-size: 14px;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: black;
        font-weight: 400;
        font-size: 14px;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10+ */
        color: black;
        font-weight: 400;
        font-size: 14px;
    }

    &.inlineInput {
        height: 36px;
        line-height: 36px;
        background: white;
        margin-bottom: 0px;
    }
}