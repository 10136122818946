.page {
    padding: 20px 28px;
    padding-left: 310px;
}

h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: black;
    display: flex;
    align-items: center;

    margin-bottom: 20px;
}

.inlineSelect {
    width: 200px;
    display: inline-block;
}

.blocksRow {
    display: flex;
    overflow-y: auto;
    gap: 16px;
}

.flex {
    display: flex;
}

.editButton {
    cursor: pointer;
    margin-left: 15px;

    &:hover,
    &:active {
        opacity: 0.7;
    }

    img {
        width: 32px;
    }
}

.link {
    cursor: pointer;
    text-decoration: underline;
}