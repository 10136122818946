.tableWrapper {

}

.customersTable {
    border: none;
    width: 100%;

    tr {
        th {
            color: black;
            font-weight: 500;
            font-size: 15px;
            line-height: 16px;
            height: 1px;

            .th {
                border-radius: 4px;
                background: var(--darkBlue);
                color: white;
                padding: 10px;
                text-align: left;
                margin: 2px;
                height: calc(100% - 20px);

                span {
                    display: block;

                    a {
                        cursor: pointer;
    
                        &.active,
                        &:hover {
                            opacity: 0.7;
                        }

                        img {
                            height: 10px;
                        }
                    }
                }
            }
        }

        td {
            color: black;
            font-weight: 500;
            font-size: 15px;
            line-height: 16px;
            height: 1px;

            .td {
                border-radius: 4px;
                background: white;
                padding: 10px;
                text-align: left;
                margin: 2px;
                height: calc(100% - 20px);
            }
        }
    }
}

.editButton {
    cursor: pointer;
    margin-left: 15px;

    &:hover,
    &:active {
        opacity: 0.7;
    }

    img {
        width: 32px;
    }
}