.visitLine {
    display: flex;
    border-bottom: solid 1px var(--grey);

    &:last-of-type {
        border-bottom: none;
    }

    .datetime {
        padding: 5px;
        width: 80px;
        flex-shrink: 0;
    }

    .ip {
        padding: 5px;
        width: 90px;
        flex-shrink: 0;
    }

    .data {
        padding: 5px;

        div {
            margin-bottom: 5px;

            &:last-of-type {
                margin-bottom: 0px;
            }
        }
    }

    .fields {
        padding: 5px;
        flex-grow: 1;
        min-width: 200px;

        div {
            margin-bottom: 5px;

            &:last-of-type {
                margin-bottom: 0px;
            }
        }
    }
}