.statisticsHolder {
    background: white;
    border-radius: 10px;
    padding: 24px;
    margin-bottom: 12px;
}

.managerInfo {
    padding: 4px 24px;
    background: var(--darkBlue);
    display: inline-flex;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 25px;
    margin-left: -20px;
    margin-top: -20px;

    span {
        display: inline-block;
        color: white;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        margin-left: 12px;
    }
}

.yearColumns {
    display: flex;

    .yearColumn {
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 87px;
        margin-right: 12px;

        span {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            display: block;
            text-align: center;
        }

        .bigYearColumn {
            height: 42px;
            background: var(--grey);
            border-radius: 6px;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: black;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }

        .smallYearColumn {
            height: 23px;
            background: var(--grey);
            border-radius: 6px;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: black;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }

        &:last-of-type {
            margin-right: 0px;
        }
    }
}

.activeCustomers {
    margin-top: 25px;

    a {
        color: black;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
    }
}

.donughtHolder {
    margin-top: 60px;
    width: 200px;
}