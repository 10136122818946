.tableWrapper {

}

.usersTable {
    border: none;

    tr {
        td {
            border-bottom: solid 1px var(--tableGrey);
            padding: 10px;
            color: black;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;

            &.longCell {
                padding: 10px 50px;
            }
        }

        &:last-of-type {
            td {
                border-bottom: none;
            }
        }
    }
}

.editButton {
    cursor: pointer;

    &:hover,
    &:active {
        opacity: 0.7;
    }

    img {
        width: 32px;
    }
}