.landingPage {
    min-height: 100vh;

    .middleBlock {
        box-sizing: border-box;
        border-radius: 5px;
        padding: 20px;
        background: white;
        box-shadow: 0 3px 10px #00000042;

        width:  900px;
        max-width: 90%;

        margin: 50px auto;

        margin-top: -300px;

        @media (max-width: 1200px) {
            width: calc(100vw - 30px);
            max-width: calc(100vw - 30px);
        }
    }

    .title {
        font-size: 25px;
        color: black;
        margin-bottom: 20px;
    }

    .description {
        font-size: 14px;
    }
}

.innerBlock {
    display: flex;
    position: relative;

    @media (max-width: 900px) {
        flex-wrap: wrap;
    }

    .textHolder {
        width:80%;

        @media (max-width: 1200px) {
            width: calc(100% - 320px);
        }

        @media (max-width: 900px) {
            width: 100%;
            margin-bottom: 30px;
        }
    }

    .formHolder {
        position: absolute;
        left: calc(80% + 20px);
        background: white;
        padding: 10px;
        width: 30%;
        box-shadow: 0 3px 10px rgb(0 0 0 / 26%);
        border-radius: 10px;

        @media (max-width: 1200px) {
            width: 300px;
            left: auto;
            right: 0px;
        }

        @media (max-width: 900px) {
            position: static;
            width: 100%;
            padding: 0px;
            box-shadow: none;
            border-top: solid 3px #F6F6F6;
            padding-top: 45px;
            border-radius: 0px;
        }
    }
}