.lineHolder {
    position: relative;
    height: 19px;

    .lineBase {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: var(--grey);
        z-index: 1;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 5px;
        font-weight: 700;
        font-size: 12px;
        line-height: 1px;
        border-radius: 5px;

        &.error {
            background: var(--lightRed);
            color: white;
        }
    }

    .lineForeground {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 5px;
        font-weight: 700;
        font-size: 12px;
        line-height: 1px;
        border-radius: 5px;
        background: var(--lightGreen);
        white-space: nowrap;
        color: black;

        &.error {
            background: var(--lightRed);
            color: white;
        }
    }

    .minimumLine {
        position: absolute;
        top: -5px;
        bottom: -5px;
        width: 1px;
        background: var(--darkgrey);
    }
}