.leftBar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    background: var(--darkBlue);
    width: 290px;
    padding: 20px 0px;
    overflow-y: auto;
}

.logoHolder {
    display: flex;
    align-items: flex-end;
    padding: 0px 30px;
    margin-bottom: 55px;
    text-decoration: none;
    justify-content: center;

    .logo {
        height: 58px;
    }

    .title {
        color: var(--lightBlue);
        font-size: 17px;
        line-height: 17px;
        font-weight: 700;
    }
}

.leftNav {
    width: 100%;
    display: flex;
    flex-direction: column;

    .item {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.01em;
        color: white;
        text-decoration: none;
        display: flex;
        align-items: center;
        margin: 12px 0px;
        padding-left: 25px;
        position: relative;
        cursor: pointer;

        .svg {
            margin-right: 10px;
            
            filter: invert(65%) sepia(0%) saturate(1976%) hue-rotate(70deg) brightness(108%) contrast(79%);
        }

        .toggle {
            position: absolute;
            right: 15px;
            top: calc(50% - 12px);

            filter: invert(65%) sepia(0%) saturate(1976%) hue-rotate(70deg) brightness(108%) contrast(79%);
        }

        &.active,
        &:hover {
            color: var(--lightBlue);

            .toggle,
            .svg {
                filter: invert(69%) sepia(43%) saturate(3366%) hue-rotate(174deg) brightness(102%) contrast(101%);
            }
        }

        &.active {
            .toggle {
                transform: rotate(180deg);
            }

            :before {
                content: '';
                top: 0;
                bottom: 0;
                left: 0;
                width: 2px;
                background: var(--lightBlue);
            }
        }
    }
}

.subMenu {
    display: none;

    &.visible {
        display: flex;
        flex-direction: column;
    }

    .subLink {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        text-decoration: none;
        padding: 9px 0px;
        padding-left: 60px;

        &.active,
        &:hover {
            color: var(--lightBlue);
            background: rgba(66, 187, 255, 0.09);
        }
    }
}