.headerRow {
    padding: 20px 28px;
    padding-left: 310px;
    display: flex;
    justify-content: space-between;
}

.rightBlock {

    .userData {
        display: flex;
        align-items: center;

        .userName {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: var(--midBlue);
            margin: 0px 16px;
        }
    }

    .angleDown {
        filter: invert(61%) sepia(11%) saturate(960%) hue-rotate(191deg) brightness(88%) contrast(87%);

        &.opened {
            filter: invert(80%) sepia(51%) saturate(5648%) hue-rotate(177deg) brightness(104%) contrast(103%);
        }
    }
}

.headerNavs {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 30px;

    a {
        cursor: pointer;
        text-decoration: none;
        display: flex;
        align-items: center;
        color: black;
        font-weight: 500;
        font-size: 14px;
        line-height: 12px;
        margin-bottom: 15px;
        justify-content: flex-end;
        white-space: nowrap;

        img {
            width: 20px;
            height: 20px;
            margin-left: 13px;
        }

        &:last-of-type {
            margin-bottom: 0px;
        }
    }
}

.searchInputHolder {
    position: relative;

    .searchInput {
        background: var(--grey);
        border-radius: 8px;
        height: 38px;
        width: 370px;
        line-height: 38px;
        padding: 0px 12px;
        padding-right: 35px;
        border: none;
        box-shadow: none;
        outline: none;
        font-weight: 500;
        font-size: 14px;
        color: black;

        &::-webkit-input-placeholder { /* WebKit browsers */
            color: var(--midBlue);
        }
        &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
            color: var(--midBlue);
        }
        &::-moz-placeholder { /* Mozilla Firefox 19+ */
            color: var(--midBlue);
        }
        &:-ms-input-placeholder { /* Internet Explorer 10+ */
            color: var(--midBlue);
        }
    }

    .searchIcon {
        position: absolute;
        right: 10px;
        top: calc(50% - 8px);
        height: 16px;
        width: 16px;
    }
}