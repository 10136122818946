.stateTitle {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: var(--darkBlue);
    fill: var(--darkBlue);
}

.mapHolder {
    position: relative;

    .iconHolder {
        position: absolute;
    }
}

.statusFilter {
    display: flex;
    overflow-x: auto;
    gap: 12px;
    margin-bottom: 15px;

    .statusBlock {
        height: 30px;
        border-radius: 36px;
        border: solid 1px black;
        padding: 1px;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        padding-right: 15px;
        padding-left: 15px;

        span {
            margin-left: 9px;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            color: black;
        }

        &.selected {
            border-color: var(--darkBlue);
            background: var(--darkBlue);

            span {
                color: white;
            }
        }
    }
}