.button {
    display: flex;
    align-items: center;
    cursor: pointer;

    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: var(--lightBlue);

    margin-left: 20px;

    img {
        height: 24px;
        margin-right: 3px;
    }
}