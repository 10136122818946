.checkbox {
    display: flex;
    flex-direction: column;

    input {
        display: none;
    }

    input[type="checkbox"] + label {
        display: flex;
        margin-bottom: 4px;
        cursor: pointer;
        align-items: center;
    }

    input[type="checkbox"] + label:before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        background: var(--grey);
        border: solid 1px var(--tableGrey);
        margin-right: 10px;
    }

    input[type="checkbox"]:checked + label:before {
        background: var(--grey) url(~/src/APP/ASSETS/green_check.png);
        background-position: center;
        background-size: 70%;
        background-repeat: no-repeat;
    }

    input[type="checkbox"]:checked + label span {
        font-weight: 900;
    }   
}